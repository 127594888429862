/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { ensureIsArray } from '@superset-ui/core';
import {
  LocalStorageKeys,
  setItem,
  getItem,
} from 'src/utils/localStorageHelpers';

export const getTimeColumns = (datasourceId?: string): string[] => {
  const colsMap = getItem(
    LocalStorageKeys.explore__data_table_original_formatted_time_columns,
    {},
  );
  if (datasourceId === undefined) {
    return [];
  }
  return ensureIsArray(colsMap[datasourceId]);
};

export const setTimeColumns = (datasourceId: string, columns: string[]) => {
  const colsMap = getItem(
    LocalStorageKeys.explore__data_table_original_formatted_time_columns,
    {},
  );
  setItem(
    LocalStorageKeys.explore__data_table_original_formatted_time_columns,
    {
      ...colsMap,
      [datasourceId]: columns,
    },
  );
};
